import WebFont from 'webfontloader';
import { emit } from '~/utils/event-bus';
import isEmpty from '~/utils/object/is-empty';

export default function (urls = {}) {
  const isRtl = window.isRtl;

  /* Async webfont loading */
  WebFont.load({
    custom: {
      families: ['P22 Underground Pro'],
      urls: isEmpty(urls)
        ? window.wizz.assetManifest.chunks.fonts[isRtl ? 'rtlCss' : 'css']
        : urls,
    },
    google: {
      families: [
        ...(isRtl ? ['Tajawal:200,300,400,500,700,900:arabic'] : []),
        `Source Sans 3:400,600,700`,
        `Montserrat:100,300,400,500,600,700,900&display=swap`,
      ],
    },
    active: () => {
      emit('webFontLoader_active');
    },
  });
}
