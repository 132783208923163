import { getCookie, getSessionItems, setCookie } from '~/utils/storage';
import isNotEmpty from '~/utils/object/is-not-empty';
import isEmpty from '~/utils/object/is-empty';
import deepMerge from '~/utils/fp/deep-merge';
import { cultures } from '~/constants/cultures';
import { setLanguage } from '~/utils/global-value/language';
import { getWizz, setWizz } from '~/utils/global-value/wizz';
import isUndefined from '~/utils/object/is-undefined';
import { getHash, isMainPage, isPasswordResetRoute } from '~/utils/browser';
import { getCultureCodeFromPath } from '~/utils/ssr/culture-code-from-path';
import {
  addEwizzOriginCookie,
  hasNoEwizzOriginCookie,
} from '~/utils/ssr/ewizz-origin-cookie-handler';
import { captureAndLogException } from '~/utils/logging';

(async () => {
  if (
    isNotEmpty(window.location.hash) &&
    isMainPage(window.location.href, {}, window.__INITIAL_STATE__) &&
    !isPasswordResetRoute()
  ) {
    history.replaceState({}, document.title, '.');
  }

  if (
    isNotEmpty(window.location.hash) &&
    isMainPage(window.location.href, {}, window.__INITIAL_STATE__) &&
    isNotEmpty(getHash().replace(/^\//, '')) &&
    hasNoEwizzOriginCookie() &&
    !isPasswordResetRoute()
  ) {
    addEwizzOriginCookie();
    return window.location.reload();
  }

  const initialState = window.__INITIAL_STATE__;
  const globalWizz = initialState.globalValue.globalWizz;

  const setupWebfont = require('~/setup/webfont').default;
  setupWebfont(initialState.globalValue.assetManifest.chunks.fonts.css);

  const state = getState();
  setLanguage(state.globalValue.language);
  setWizz({ ...window.wizz, ...getWizz(state), ...globalWizz });

  const { createApp } = await import('./app.js');
  // eslint-disable-next-line vue/require-name-property
  const { app, router, store } = await createApp({ state });

  router.onReady(async () => {
    const { getResourceScripts } = await import('~/store/modules/global-value/getters');
    try {
      // eslint-disable-next-line no-eval
      eval?.(getResourceScripts(store.state));
    } catch (error) {
      captureAndLogException(`Could not evaluate resource scripts.`, error);
    }

    // @TODO Please check for possible issues after go live
    // const globalValueActions = await import('~/store/modules/global-value/actions');
    // globalValueActions.resetResourceScripts(store);

    (await import('~/i18n')).update();

    app.$mount('#ssr-app', true);
  });
})();

function getState() {
  const sessionItems = getSessionItems();
  const initialState = window.__INITIAL_STATE__;
  const { locale, globalValue } = sessionItems;
  const { locale: initialLocale, globalValue: initialGlobalValue } = initialState;
  const cultureCodeFromUrl = getCultureCodeFromPath(location.pathname);
  const forceLang = getCookie('stored-culture') ?? localStorage.getItem('stored-culture');
  const restorableModules = ['user', 'locale'];

  const defaultState = {
    globalValue: {
      ...(initialGlobalValue.assetManifest && {
        assetManifest: initialGlobalValue.assetManifest,
      }),
      ...(initialGlobalValue.globalWizz && {
        globalWizz: initialGlobalValue.globalWizz,
      }),
    },
  };

  if (isEmpty(cultureCodeFromUrl) || isUndefined(cultures[cultureCodeFromUrl])) {
    if (
      isNotEmpty(forceLang) &&
      (isEmpty(locale?.languageCode) ||
        (isNotEmpty(locale?.languageCode) &&
          locale.languageCode !== initialLocale.languageCode))
    ) {
      const extraPath = location.hash.replace('#/', '').replace('#', '');

      const currentPageUrl = initialState.cms.currentPage.url;
      const _currentPageUrl =
        currentPageUrl === '/' ? '' : currentPageUrl.replace('/main-page', '');

      const queryString = location.search;
      const slashOrEmpty = _currentPageUrl || extraPath ? '/' : '';

      window.location.replace(
        `/${forceLang}${slashOrEmpty}${_currentPageUrl}${extraPath}${queryString}`
      );
    } else if (
      isNotEmpty(locale?.languageCode) &&
      isNotEmpty(globalValue?.language.culture) &&
      locale.languageCode === globalValue.language.culture &&
      locale.languageCode === initialLocale.languageCode
    ) {
      restorableModules.forEach((e) => delete initialState[e]);
    }

    return deepMerge({ ...sessionItems, ...initialState }, { ...defaultState });
  }

  localStorage.setItem('stored-culture', cultureCodeFromUrl);
  setCookie('stored-culture', cultureCodeFromUrl, {
    maxAge: 31536000, // 1 year
    path: '/',
  });
  if (cultureCodeFromUrl === 'ar-ae' || cultureCodeFromUrl === 'he-il') {
    document.body.parentElement.className += ' rtl';
    document.body.parentElement.setAttribute('dir', 'rtl');
  }
  if (
    isNotEmpty(locale?.languageCode) &&
    cultureCodeFromUrl === initialLocale.languageCode &&
    cultureCodeFromUrl === globalValue?.language.culture
  ) {
    restorableModules.forEach((e) => delete initialState[e]);
  }

  return deepMerge({ ...sessionItems, ...initialState }, { ...defaultState });
}
