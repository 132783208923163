import Cookies from 'js-cookie';
import isNotEmpty from '../object/is-not-empty';

const cookieName = 'ewizz-origin';
const cookieValue = 'f40c14ef90b348274466a696b4d6e9ab';

export const hasNoEwizzOriginCookie = () => {
  return !hasEwizzOriginCookie();
};

export const hasEwizzOriginCookie = () => {
  return isNotEmpty(getEwizzOriginCookie());
};

const getEwizzOriginCookie = () => {
  return Cookies.get(cookieName);
};

export const addEwizzOriginCookie = () => {
  Cookies.set(cookieName, cookieValue);
};

export const removeEwizzOriginCookie = () => {
  Cookies.set(cookieName, '');
};
