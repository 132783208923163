const getCultureCodeFromPath = (url) => {
    const regExpToGetCultureCode = /\/([^#/?]+)/;
    const cultureCodeInUrl = url.match(regExpToGetCultureCode)?.[1] ?? '';

    return  cultureCodeInUrl.toLowerCase();
}

// eslint-disable-next-line no-undef
module.exports = {
    getCultureCodeFromPath,
}
